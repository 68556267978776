import type { MessageType } from '@marty-js/design/src/utils/translation';
import { messageFr } from '@marty-js/design/src/utils/message/message.fr';
import { messageFr as SdkMessageFr } from '@marty-js/sdk/src/translation/message.fr';

export const message: MessageType = {
  locale: 'fr',
  localeTimePattern: "dd MMMM yyyy 'à' HH'h'mm",
  localeTimeSoftWare: 'dd/MM/yyyy',
  decimalSeparator: ',',
  metaSeo: {
    page: ' - page {{page}}',
  },
  feed: {
    msn: {
      relative: 'A lire aussi',
    },
  },
  _app: {
    metaSeo: {
      description:
        'News, tests, comparatifs, téléchargements...Venez partager votre passion des nouvelles technologies avec notre communauté !',
      imageUrl: 'https://pic.clubic.com/v1/images/1876284/raw',
      title: "Clubic - La meilleure source d'information Tech",
      url: 'https://www.clubic.com/',
    },
  },
  errorPage: {
    '404': {
      backLink: 'Retour sur Clubic',
      footerText: {
        '0': 'On voulait vous faire la blague de la Peugeot 404...',
        '1': 'Mais elle était déjà prise, alors voilà... un Fiat Multipla.',
      },
      metaSeo: { title: 'Clubic - 404' },
      subTitle: "Cette page n'existe pas",
      text: "La page que vous avez demandé n'a pas été trouvée.",
      title: '404',
    },
    '500': {
      backLink: 'Retour sur Clubic',
      metaSeo: { title: 'Clubic - 500' },
      subTitle: 'Une erreur est survenue',
      text: 'Merci de revenir plus tard.',
      title: '500',
    },
  },
  footer: {
    copyright: '© CLUBIC SAS {{year}}',
    newsletter: {
      title: 'Abonnez-vous à notre newsletter !',
      text: "Recevez un résumé quotidien de l'actu technologique.",
      placeholder: 'Votre adresse e-mail',
      unsubscribe:
        'Désinscrivez-vous via le lien de désinscription présent sur nos newsletters ou écrivez à : desinscription@clubic.com. En savoir plus sur le traitement de ',
      unsubscribeAnchor: 'données personnelles.',
      button: "S'inscrire",
    },
    title: 'Indépendance, transparence et expertise',
    text: 'Clubic est un média de recommandation de produits 100% indépendant. Chaque jour, nos experts testent et comparent des produits et services technologiques pour vous informer et vous aider à consommer intelligemment.',
  },
  header: {
    darkModeLabel: 'Dark mode',
    displayMenuLabel: 'Afficher menu',
    searchPlaceHolder: 'Rechercher un logiciel, un produit, un article...',
    hideMenuLabel: 'Masquer le menu',
    altLogo: 'Logo de Clubic',
    search: 'Rechercher',
    account: 'Compte utilisateur',
    login: 'Se connecter',
    profile: 'Gestion du profil',
    logout: 'Se déconnecter',
    forum: 'Forum',
    findASoftware: 'Trouver un logiciel',
  },
  mobileMenu: { closeMenu: 'Fermer le menu' },
  modalTheme: { title: 'Choose your destiny', kernel: 'Kernel', legacy: 'Legacy' },
  modalSearch: { button: 'Search' },
  search: {
    button: 'Rechercher',
    title: 'Rechercher: ({{keyword}})',
    input: { placeholder: 'VPN' },
    noKeyword: 'Saisissez votre recherche dans le champ ci-dessus.',
    inProgress: 'Recherche en cours...',
    noResult: ' Pas de résultat pour votre recherche.',
    nbResults: '{{nb}} résultats pour votre recherche.',
    oneResult: 'Un seul résultat pour votre recherche.',
    manyResult: 'Plus de 1000 résultats pour votre recherche.',
    pageTitle: 'Clubic | Recherche par motclefs',
  },
  contact: {
    name: 'Nom',
    subject: 'Sujet',
    message: 'Message',
    email: 'Email',
    reason: 'Quel est le sujet de votre message ?',
    contact_reason: {
      other: 'Autre question',
      clubic_article: 'Un article Clubic',
      download: 'Un téléchargement',
      business: 'Une demande commerciale',
      bug: 'Un problème technique',
    },
  },
  published: 'Publié',
  by: 'par',
  ...messageFr,
  ...SdkMessageFr,
};
