import React, { useEffect, useMemo } from 'react';
import { createGlobalStyle, css, ThemedStyledProps } from 'styled-components';
import classNames from 'classnames';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import type { Theme } from '@marty-js/design/src/utils/types';
import { useAdContext } from './AdContext';
import consoleDebug from '../utils/consoleDebug';

const adsForeground = (props: ThemedStyledProps<{ minWidth?: number; minHeight?: number }, Theme>) =>
  props.theme.isDark ? 'rgba(255,255,255,0.15)' : 'rgba(35,39,40,0.15)';

const adsBackground = (props: ThemedStyledProps<{ minWidth?: number; minHeight?: number }, Theme>) =>
  props.theme.isDark ? '#1f1f1f' : '#f7f7f7';

export const GlobalAdStyle = createGlobalStyle<{ minWidth?: number; minHeight?: number }>`
  .ad-container {
    background-color: ${adsBackground};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    min-height: 200px;
    overflow: hidden;
    grid-column: 2 / 3;

    ${mq.gte(
      DEVICE_SIZE.LARGE,
      css`
        grid-column: 1 / 10;
      `,
    )}

    p {
      font-size: 14px;
      color: ${adsForeground};
      margin: 8px 0;
    }
    
    .ad-content {
      flex-grow: 1;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      max-width: 100vw;
      overflow: hidden;

      &[data-slot="Billboard_2"],
      &[data-slot="Mobile_Pos1"],
      &[data-slot="Mobile_Pos2"],
      &[data-slot="Mobile_Pos3"] {
        min-height: 250px;
      }

      &[data-slot="Billboard_1"] {
        height: 250px;
      }

      &[data-slot="Rectangle_1"] {
        height: 100px;
      }

      &[data-slot="HalfpageAd_1"],
      &[data-slot="HalfpageAd_2"],
      &[data-slot="HalfpageAd_3"] {
        min-height: 600px;
      }
    }
    
    &.no-background {
      background-color: transparent;
      border: none;
      overflow: visible;
      
      p {
        display: none;
      }
      .ad-content {
        margin-bottom: 0;
      }
    }

    &.megaban {
      margin: var(--spacer-s) 0;
    }

    &.position-sticky {
      position: sticky;
      top: 85px;
    }
    
    &.mobile-only {
      display: flex;
      ${mq.gte(
        DEVICE_SIZE.LARGE,
        css`
          display: none;
        `,
      )}
    }

    &.desktop-only {
      display: none;
      ${mq.gte(
        DEVICE_SIZE.LARGE,
        css`
          display: flex;
        `,
      )}
    }
  }

  #GenecyDFPAdWallpaperCont ~ * .ad-container.megaban {
    display: none;
  }
`;

interface Props {
  className: string;
  minWidth?: number;
  minHeight?: number;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  noBackground?: boolean;
  sticky?: boolean;
  megaban?: boolean;
  adUnitCondition?: string;
}

/*
const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    const url = 'https://www3.doubleclick.net';
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then(({ redirected }) => {
        if (redirected) setAdBlockDetected(true);
      })
      .catch(() => {
        setAdBlockDetected(true);
      });
  }, []);

  return adBlockDetected;
};
 */

const checkMemo = (prevProps: Props, nextProps: Props) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

const SimpleAd = React.memo(({ className }: Partial<Props>) => {
  useEffect(() => {
    if (className === 'Billboard_1' && document?.getElementById('GenecyDFPAdWallpaperCont')) {
      document?.getElementById('GenecyDFPAdWallpaperCont').remove();
    }
  }, [className]);

  consoleDebug({ component: 'SimpleAd', className });

  return (
    <div
      className="ad-content"
      data-slot={className}
      dangerouslySetInnerHTML={{
        __html: `<div class='${className}' style='display: none;'></div>`,
      }}
    />
  );
}, checkMemo);

export const Ad = ({
  className,
  minHeight,
  minWidth,
  mobileOnly,
  desktopOnly,
  noBackground,
  sticky,
  megaban,
  adUnitCondition,
}: Props) => {
  const containerClass = classNames('ad-container', {
    'mobile-only': mobileOnly,
    'desktop-only': desktopOnly,
    'no-background': noBackground,
    'position-sticky': sticky,
    megaban,
  });

  const adContext = useAdContext();
  const showPub = useMemo(
    () => !(adUnitCondition && adUnitCondition !== adContext.shortAdUnit),
    [adUnitCondition, adContext.shortAdUnit],
  );

  if (adContext.tags?.includes('nopub')) {
    return null;
  }

  consoleDebug({ component: 'Ad', showPub, className });

  return (
    <div className={containerClass} style={{ minWidth, minHeight }}>
      {showPub ? <SimpleAd className={className} /> : null}
    </div>
  );
};
