import { createGlobalStyle } from 'styled-components';
import type { StyledTheme } from './types';
import {
  palette,
  typography,
  darkThemeColor,
  lightThemeColor,
  lightBackgroundTheme,
  darkBackgroundTheme,
} from '../themes/site-clubic';

function transformTheme(prefix: string, t: any) {
  let result: string = '';
  Object.entries(t).forEach(([key1, properties]) => {
    Object.entries(properties).forEach(([key2, value]) => {
      const cssVarKey = `--${prefix}-${key1}-${key2}`.replace(/([A-Z])/g, '-$1').toLowerCase();
      result += `${cssVarKey}: ${value};\n`;
    });
  });

  return result;
}

const globalTheme = {
  typography,
  palette,
};

export const InjectRootStyles = createGlobalStyle<StyledTheme>`
  :root {
    --spacer-xs: 7px;
    --spacer-s: 15px;
    --spacer-s-fluid: clamp(0.625rem, 0.536rem + 0.446vw, 0.938rem);

    --spacer: 30px;
    --spacer-to-xs: clamp(0.438rem, 0.027rem + 2.054vw, 1.875rem);
    --spacer-fluid: clamp(0.938rem, 0.67rem + 1.339vw, 1.875rem);

    --spacer-m: 45px;
    --spacer-m-to-xs: clamp(0.438rem, -0.241rem + 3.393vw, 2.813rem);
    --spacer-m-to-s: clamp(0.938rem, 0.402rem + 2.679vw, 2.813rem);
    --spacer-m-fluid: clamp(1.875rem, 1.607rem + 1.339vw, 2.813rem);

    --spacer-l: 60px;
    --spacer-l-to-xs: clamp(0.438rem, -0.509rem + 4.732vw, 3.75rem);
    --spacer-l-to-s: clamp(0.938rem, 0.134rem + 4.018vw, 3.75rem);
    --spacer-l-to-default: clamp(1.875rem, 1.339rem + 2.679vw, 3.75rem);
    --spacer-l-fluid: clamp(2.813rem, 2.545rem + 1.339vw, 3.75rem);

    --spacer-xl: 90px;
    --spacer-xl-to-xs: clamp(0.438rem, -1.045rem + 7.411vw, 5.625rem);
    --spacer-xl-to-s: clamp(0.938rem, -0.402rem + 6.696vw, 5.625rem);
    --spacer-xl-to-default: clamp(1.875rem, 0.804rem + 5.357vw, 5.625rem);
    --spacer-xl-to-m: clamp(2.813rem, 2.009rem + 4.018vw, 5.625rem);
    --spacer-xl-fluid: clamp(3.75rem, 3.214rem + 2.679vw, 5.625rem);
    --spacer-em: 1em;
    ${transformTheme('theme', globalTheme)}  
  }
  
  :root[data-theme='light'] {
      ${transformTheme('theme', { color: lightThemeColor })}
      ${transformTheme('theme-background', lightBackgroundTheme)}
      --background-color-sub: #EDF0F4;
      --text-color:  var(--theme-palette-dark-black);
      --text-color-alt: var(--theme-palette-blue);
      --title-foreground: var(--theme-palette-black-alt);
      --link-color: var(--theme-palette-red);
      --background-color-alt: var(--theme-palette-black-alt);
      --color-box-shadow: rgba(0 ,0 ,0 ,0.1);
      --color-alt-blue: var(--theme-palette-blue-medium);
      --color-alt-grey-blue: var(--theme-palette-grey-blue);
      --color-alt-1: var(--theme-palette-grey);
      --color-alt-2: var(--theme-palette-dark-white);
      --color-alt-3: var(--theme-palette-dark-white);
      --color-alt-4: none;
      --color-alt-5: var(--theme-palette-dark-white);
      --color-alt-6: var(--theme-palette-dark-grey-blue);
      --color-alt-7: var(--theme-palette-dark-grey);
      --color-alt-8: var(--theme-palette-white);
  }
  
  :root[data-theme='dark'] {
      ${transformTheme('theme', { color: darkThemeColor })}
      ${transformTheme('theme-background', darkBackgroundTheme)}
      --background-color-sub: var(--theme-palette-dark-black);
      --text-color: var(--theme-palette-white);
      --text-color-alt: var(--theme-palette-white);
      --title-foreground: var(--theme-palette-white);
      --link-color: var(--theme-palette-red-medium);
      --background-color-alt: var(--theme-palette-black);
      --color-box-shadow: rgba(0 ,0 ,0 ,0.3);
      --color-alt-blue: var(--theme-palette-blue);
      --color-alt-grey-blue: var(--theme-palette-light-grey-blue);
      --color-alt-1: var(--theme-palette-white);
      --color-alt-2: var(--theme-palette-black-alt);
      --color-alt-3: var(--theme-palette-white);
      --color-alt-4: var(--theme-palette-dark-black);
      --color-alt-5: var(--theme-palette-dark-black);
      --color-alt-6: var(--theme-palette-white);
      --color-alt-7: var(--theme-palette-dark-white);
      --color-alt-8: var(--theme-palette-red-medium);
  }
  
  html {
    color: var(--text-color);
    background-color: var(--theme-color-background);
    transition: background-color 0.5s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    line-height: 1.2;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-family: var(--theme-typography-default-font);
    overflow-x: hidden;
  }

  article aside {
    display: none;
  }
  
  html.no-event {
    &,
    body {
      overflow: hidden;
    }
  }

  a {
    color: inherit;
  }
  
  a.un-styled-linked {
    text-decoration: none;
  }
  
  a.mod-primary {
    color: var(--link-color);
  }

  a.simple {
    color: var(--link-color);
    font-weight: bold;
  }
  
  blockquote {
    font-style: italic;
  }

  @media (prefers-reduced-motion: reduce) {
    *,
    *:hover,
    *:focus {
      &,
      &::before,
      &::after {
        animation: none;
        transition: none;
        scroll-behavior: auto;
      }
   }
  }
`;
